@import url('@fullcalendar/core/main.css');
@import url('@fullcalendar/daygrid/main.css');
@import url('@fullcalendar/timegrid/main.css');

.hfn-calendar {

  .fc th.fc-widget-header {
    background: #f2f6fe;
  }

  .fc-button-primary {
    background-color: $primary;
    border: $primary;
  }

  .fc-button-primary:hover {
    background-color: $primaryHover;
  }

  .fc-button-primary:not(:disabled):active,
  .fc-button-primary:not(:disabled).fc-button-active {
    background-color: $primaryHover;
  }

  .fc-button-primary:focus {
    box-shadow: unset !important;
  }

  .fc-dayGrid-view .fc-body .fc-row {
    min-height: 2.5rem;
  }

  .fc-unthemed td.fc-today {
    background: #727cf52b;
  }

  .fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: .8125rem;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
    color: #fff;
  }

  .fc-day-grid-event .fc-content {
    display: flex;

    .fc-time {
      min-width: 40px;
      min-width: 40px;
      text-align: left;
    }

    .fc-title {
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      word-break: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 2px 10px;
      text-align: right;
    }
  }

}

.calendar-popup-button-group {
  border-top: 1px solid #e9ecef;
  background: #ffffff;
  color: #212529;
  padding: 1rem;
  text-align: right;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  position: absolute;
  margin: 0;
  width: 100%;
  left: 0;
}