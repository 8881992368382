$primary   : #2196F3;
$info      : #7239ea;
$secondary : #f5f8fa;
$success   : #50cd89;
$danger    : #f1416c;
$warning   : #ffc700;
$bcolor    : #2F80ED;
$focus     : #0047FF;
$bg        : #f4f7ff;

$color : #3f4254;

$border-color : #eff2f5;

$primaryHover   : #0d89ec;
$infoHover      : #5014d0;
$secondaryHover : #b5b5c3;
$successHover   : #47be7d;
$dangerHover    : #d9214e;
$warningHover   : #ffc700;